import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import getAxios from "../../../../services/axios-get";
import putAxios from "../../../../services/axios-put";
import patchAxios from "../../../../services/axios-patch";

import uploadImage from "../../../../services/image-uploader";
import uploadVideo from "../../../../services/video-uploader";
import uploadInspectedPdf from "../../../../services/inspected-uploader";

import postAxios from "../../../../services/axios-post";

import DatetimePicker from "vuetify-datetime-picker";

Vue.use(DatetimePicker);
Vue.use(VueAxios, axios);

export default {
  data() {
    return {
      inspectedToggle: null,
      inspectedFilePathLocal: null,
      // biddingResults: [],
      type: null,
      alertMsg: null,
      biddingSearch: "",
      biddingCarPlate: "",
      biddingStatus: "",
      token: "",
      pageCount: 0,
      imgFile: null,
      editImgFile: null,
      defaultImgPath: null,
      imgUrl: null,
      coeDateModal: false,
      regDateModal: false,
      renewDateModal: false,
      dialog: false,
      editRemarksDialog: false,
      editDialog: false,
      editScheduleDialog: false,
      bidPrice: null,
      biddingResultId: null,
      resultDialog: false,
      snackbar: false,
      snackMessage: "",
      editableBidPrice: true,
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      salepersonJSON: null,
      dealerJSON: null,
      carModelJSON: null,
      search: null,
      searchDealer: null,
      searchSalesperson: null,
      addUsedCar: {
        arfPrice: 0,
        carModelCode: "",
        carPlateNumber: "",
        coeCategoryId: "",
        coeDate: new Date().toISOString().substr(0, 10),
        coePrice: 0,
        coeType: "",
        color: "",
        defaultImgPath: "",
        depreciation: 0,
        displayImgs: [],
        draft: false,
        efficiency: 0,
        idNum: "",
        idTypeId: "",
        isDraft: false,
        mileage: 0,
        notes: "",
        numOfOwner: 0,
        offPeakCarFlag: 0,
        omvPrice: 0,
        ownerName: "",
        priceHiddenFlag: 0,
        productTitle: "",
        regDate: new Date().toISOString().substr(0, 10),
        renewCoeDate: new Date().toISOString().substr(0, 10),
        roadTax: 0,
        salePrice: 0,
        salepersonIds: [],
        scrapValue: 0,
        vesCategoryId: "",
        videoPaths: [],
      },
      headers: [
        { text: "Id", value: "id" },
        { text: "Name", value: "productTitle" },
        { text: "Bid Start Time", value: "bidStartTime" },
        { text: "Bid End Time", value: "bidEndTime" },
        { text: "Max Reserved Price", value: "maxReservePrice" },
        {
          text: "Current Highest Dealer",
          value: "dealerName",
          sortable: false,
        },
        { text: "Notes", value: "editorNotes" },
        { text: "Status", value: "status" },
        { text: "Inspected", sortable: false },
        { text: "Hide", value: "hideFlag", sortable: false },
        { text: "Result", sortable: false },
        { text: "Edit Timing", sortable: false },
        { text: "Approval", sortable: false },
        { text: "WinnerInfo", sortable: false },
        { text: "WinnerRemarks", value: "remarks" },
      ],
      biddingWinnerInfo: {
        biddingId: null,
        remarks: null,
        accType: null,
        dealerId: null,
        dealerName: null,
        dealerPhone: null,
        email: null,
        name: null,
        phone: null,
        zValue: null,
      },
      editBiddingSchedule: {
        id: null,
        bidEndTime: new Date(),
        bidStartTime: new Date(),
      },
      bidderInfoDialog: false,
      biddingList: [],
      displayImgsFiles: [],
      displayVideoFiles: [],
      salespersonEntries: [],
      dealerEntries: [],
      coeTypeList: [],
      idTypeList: [],
      vesCategoryList: [],
      coeCatList: [],
      biddingResult: null,
      resultRankList: ["WINNER", "SECOND", "THIRD"],
      isLoading: false,
      editBidding: {
        name: "",
        biddingId: "",
        status: "",
      },
      biddingStatusList: [
        { text: "PENDING APPROVAL", value: "PENDING_APPROVAL" },
        { text: "APPROVED", value: "APPROVED" },
        { text: "REJECTED", value: "REJECTED" },
        { text: "SOLD", value: "ENDED_SOLD" },
        { text: "NOT SOLD", value: "ENDED_NOT_SOLD" },
      ],
      biddingWinnerInfoList: [
        { text: "Pending Viewing", value: "Pending Viewing" },
        { text: "Car Sold", value: "Car Sold" },
        { text: "Pending", value: "Pending" },
        { text: "Price Too Low", value: "Price Too Low" },
        { text: "Considering", value: "Considering" },
      ],
      sortValue: null,
      sortBy: null,
      sortDesc: null,
    };
  },
  mounted() {
    this.fetchCredentials();
    this.fetchBiddingList();
    this.fetchVesCat();
    this.fetchCOECat();
    this.fetchCOEType();
    this.fetchIDType();
  },
  computed: {
    items() {
      return this.entries;
    },
    salesPersonItems() {
      return this.salespersonEntries;
    },
    dealershipItems() {
      return this.dealerEntries;
    },
  },
  watch: {
    // Items have already been loaded
    search(val) {
      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;
      console.log("Loading here: " + val);

      // Lazily load input items
      fetch(
        `https://hades.ucars.sg/relatedContent/carModelPair?searchContent=${val}`
      )
        .then(res => res.json())
        .then(res => {
          // const { count, entries } = res;
          // this.count = count;
          this.entries = res.data.content;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    searchSalesperson() {
      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items

      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/dealerInfo/dealerSaleperson`,
        {
          dealerId: self.dealerJSON.id,
        },
        self.token
      )
        .then(res => {
          if (res.data) {
            self.salespersonEntries = res.data.data.content;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    searchDealer(val) {
      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items

      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/userSystem/dealer`,
        {
          searchContent: val,
        },
        self.token
      )
        .then(res => {
          if (res.data) {
            self.dealerEntries = res.data.data.content;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  methods: {
    sortItems({ sortBy, sortDesc }) {
      let columnName;
      if (sortBy[0] === "productTitle") {
        columnName = "car.productTitle";
      } else if (sortBy[0] === "bidStartTime") {
        columnName = "biddingSchedule.bidStartTime";
      } else if (sortBy[0] === "bidEndTime") {
        columnName = "biddingSchedule.bidEndTime";
      } else if (sortBy[0] === "maxReservePrice") {
        columnName = "latestPrice";
      } else {
        columnName = sortBy[0];
      }
      if (columnName && sortDesc[0] === true) {
        this.sortValue = columnName + ",desc";
      } else if (columnName && sortDesc[0] === false) {
        this.sortValue = columnName;
      } else {
        this.sortValue = "car.createTime,desc";
      }
      this.fetchBiddingList();
    },
    getLastBiddingPrice(biddingId) {
      const filterItem = encodeURI(`[["bidding.id", "eq", "${biddingId}"]]`);
      const sortItem = encodeURIComponent(`bidPrice,desc`);
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/api/v2/car-bid-record?filter=${filterItem}&sort=${sortItem}`,
        this.token
      ).then(res => {
        if (res.data) {
          this.biddingResultId = res.data.items[0].id;
        }
      });
    },
    Images_onFileChanged(event) {
      this.inspectedFilePathLocal = event.target.files[0];
    },
    editScheduleButtonClick(item) {
      this.editBiddingSchedule.id = item.biddingScheduleId;
      this.editBiddingSchedule.bidEndTime = new Date(item.bidEndTime);
      this.editBiddingSchedule.bidStartTime = new Date(item.bidStartTime);
      this.editScheduleDialog = true;
    },
    async doEditBiddingSchedule() {
      var self = this;
      try {
        const params = {
          id: self.editBiddingSchedule.id,
          bidEndTime: self.editBiddingSchedule.bidEndTime,
          bidStartTime: self.editBiddingSchedule.bidStartTime,
        };
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/biddingSystem/updBiddingSchedule`,
          params,
          self.token
        )
          .then(res => {
            if (res.data) {
              self.editScheduleDialog = false;
              self.fetchBiddingList();
              self.showAlert(
                "success",
                "Bidding Schedule Edited Successfully!"
              );
            }
          })
          .catch(error => {
            self.editScheduleDialog = false;
            this.showAlert("error", error.response.data.message);
          });
      } catch (e) {
        self.showAlert("error", e);
        console.error(e);
      }
    },
    changeBidPrice() {
      const updatePrice = {
        bidPrice: this.bidPrice,
      };
      patchAxios(
        `${process.env.VUE_APP_SERVER_URL}/api/v2/car-bid-record/${this.biddingResultId}`,
        updatePrice,
        this.token
      )
        .then(res => {
          if (res.data) {
            this.snackbar = true;
            this.snackMessage = "Bidding Price Updated Successfully!";
            this.editableBidPrice = true;
          }
        })
        .catch(err => {
          console.log(err);
          this.snackbar = true;
          this.snackMessage = "Error!";
        });
    },
    async uploadButtonClick(item) {
      const self = this;
      try {
        if (
          this.inspectedFilePathLocal != null &&
          this.inspectedFilePathLocal.type != "application/pdf"
        ) {
          self.showAlert("error", "Please select a PDF to upload");
          return;
        }
        if (this.inspectedFilePathLocal == null) {
          item.inspected = "no";
        } else {
          const uploadRes = await uploadInspectedPdf(
            this.inspectedFilePathLocal
          );
          if (uploadRes.data.httpStatus !== "OK") {
            return;
          }
          item.inspectedFilePath = uploadRes.data.data;
          item.inspected = "yes";
        }
        this.doEditInspected(item.id, item);
      } catch (err) {
        console.log(err);
      }
    },
    resultButtonClick(item) {
      const self = this;
      const params = {
        biddingScheduleId: item.biddingScheduleId,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/biddingSystem/generateBiddingReport`,
        params,
        self.token
      )
        .then(res => {
          if (res.data) {
            var arr = res.data.data;
            self.biddingResult = arr;
            this.bidPrice =
              this.biddingResult.top3BidderDetails.length > 0
                ? this.biddingResult.top3BidderDetails[0].bidPrice
                : null;
            this.getLastBiddingPrice(self.biddingResult.biddingId);
          }
        })
        .catch(error => {
          self.biddingResult = null;
          self.showAlert("error", error.response.data.message);
        });
      this.resultDialog = true;
      this.editableBidPrice = true;
    },
    chooseImage() {
      this.addUsedCar.logoName = this.imgFile.name;
      this.imgUrl = URL.createObjectURL(this.imgFile);
    },
    async doAddUsedCar() {
      const self = this;
      try {
        const imgRes = await uploadImage(self.defaultImgPath);
        if (imgRes.data.httpStatus !== "OK") {
          return;
        }
        let displayImgArray = [];
        for (let i = 0; i < this.displayImgsFiles.length; i++) {
          let imageRes = await uploadImage(self.displayImgsFiles[i]);
          if (imageRes.data.httpStatus === "OK") {
            displayImgArray.push(imgRes.data.data);
          }
        }
        let displayVideoArray = [];
        for (let i = 0; i < this.displayVideoFiles.length; i++) {
          let videoRes = await uploadVideo(self.displayVideoFiles[i]);
          if (videoRes.data.httpStatus === "OK") {
            displayVideoArray.push(imgRes.data.data);
          }
        }
        self.addUsedCar.videoPaths = displayVideoArray;
        self.addUsedCar.displayImgs = displayImgArray;
        self.addUsedCar.defaultImgPath = imgRes.data.data;
        self.addUsedCar.carModelCode = self.carModelJSON.code;
        self.addUsedCar.salepersonIds.push(self.salepersonJSON.id);
        // console.log("used car: " + JSON.stringify(self.addUsedCar));
        postAxios(
          `${process.env.VUE_APP_SERVER_URL}/carSystem/addCarUsedForDealer?dealerId=` +
            self.dealerJSON.id,
          self.addUsedCar,
          self.token
        ).then(res => {
          if (res.data) {
            self.dialog = false;
            self.fetchUsedCar();
            self.showSnack("Add Car Successfully", "success");
          }
        });
      } catch (e) {
        self.showSnack("Add Car Failed" + e, "error");
        console.error(e);
      }
    },
    searchBidding() {
      this.fetchBiddingList();
    },
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchBiddingList();
    },
    editButtonClick(item) {
      this.editBidding.name = item.name;
      this.editBidding.status = item.status;
      this.editBidding.biddingId = item.id;

      this.editDialog = true;
    },
    async doEditBidding() {
      const self = this;

      try {
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/biddingSystem/updBiddingStatus?biddingId=` +
            this.editBidding.biddingId +
            "&status=" +
            this.editBidding.status,
          this.editBidding,
          self.token
        ).then(res => {
          if (res.data.httpStatus === "OK") {
            self.editDialog = false;
            self.fetchBiddingList();
            self.$message({
              message: "Bidding Edited Successfully!",
              type: "success",
            });
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    fetchBiddingList() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
        searchContent: self.biddingSearch,
        sort: self.sortValue,
        status: self.biddingStatus,
        carPlate: self.biddingCarPlate,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/biddingSystem/customerBidding`,
        params,
        self.token
      )
        .then(res => {
          if (res.data) {
            self.biddingList = res.data.data.content;
            self.pagination.noOfPage = res.data.data.totalPages;
          }
        })
        .catch(error => {
          self.showAlert("error", error.response.data.message);
        });
    },
    fetchVesCat() {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/generic/vesCategory`,
        {},
        ""
      ).then(res => {
        if (res.data) {
          self.vesCategoryList = res.data.data;
        }
      });
    },
    fetchCOECat() {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedContent/coeCategoryPair`,
        {},
        ""
      ).then(res => {
        if (res.data) {
          self.coeCatList = res.data.data.content;
        }
      });
    },
    fetchCOEType() {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/generic/categoryType`,
        {},
        ""
      ).then(res => {
        if (res.data) {
          self.coeTypeList = res.data.data;
        }
      });
    },
    fetchIDType() {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedContent/idTypePair`,
        {},
        ""
      ).then(res => {
        if (res.data) {
          self.idTypeList = res.data.data.content;
        }
      });
    },
    fetchCredentials() {
      var self = this;
      const token = localStorage.getItem("jwt");
      if (token) {
        self.token = token;
      } else {
        self.showAlert("error", "unauthorized..");
      }
    },
    winnerInfoButtonClick(item) {
      if (item.biddingWinnerInfo) {
        this.biddingWinnerInfo.accType = item.biddingWinnerInfo.accType;
        this.biddingWinnerInfo.dealerName = item.biddingWinnerInfo.dealerName
          ? item.biddingWinnerInfo.dealerName
          : "";
        this.biddingWinnerInfo.dealerPhone = item.biddingWinnerInfo.dealerPhone;
        this.biddingWinnerInfo.email = item.biddingWinnerInfo.email;
        this.biddingWinnerInfo.name = item.biddingWinnerInfo.name;
        this.biddingWinnerInfo.phone = item.biddingWinnerInfo.phone;
        this.biddingWinnerInfo.zValue = item.biddingWinnerInfo.zValue;
      }
      this.biddingWinnerInfo.biddingId = item.id;
      this.biddingWinnerInfo.remarks = item.remarks ? item.remarks : "";
      this.bidderInfoDialog = true;
    },
    showEditRemarksDialog(item) {
      this.editRemarksDialog = true;
      console.log(item);
    },

    doEditRemarks(biddingId, remarks) {
      const self = this;
      try {
        const data = {
          biddingId: biddingId,
          remarks: remarks,
        };
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/biddingSystem/updBidding`,
          data,
          self.token
        )
          .then(res => {
            if (res.data.httpStatus === "OK") {
              self.fetchBiddingList();
              self.editRemarksDialog = false;
              self.showAlert("success", "Remarks Edited Successfully!");
            }
          })
          .catch(error => {
            self.showAlert("error", error.response.data.message);
          });
      } catch (e) {
        self.showAlert("error", e);
      }
    },
    showAlert(type, message) {
      this.type = type;
      this.alertMsg = message;
      let timer = this.showAlert.timer;
      if (timer) {
        clearTimeout(timer);
      }
      this.showAlert.timer = setTimeout(() => {
        this.type = null;
      }, 3000);
      let t = this.showAlert.t;
      if (t) {
        clearInterval(t);
      }
    },
    onToggleHide(item) {
      const self = this;
      try {
        const data = {
          biddingId: item.id,
          hideFlag: item.hideFlag ? 1 : 0,
        };
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/biddingSystem/updBidding`,
          data,
          self.token
        )
          .then(res => {
            if (res.data.httpStatus === "OK") {
              self.fetchBiddingList();
              self.editRemarksDialog = false;
              self.showAlert("success", "Hide updated Successfully!");
            }
          })
          .catch(error => {
            self.showAlert("error", error.response.data.message);
          });
      } catch (e) {
        self.showAlert("error", e);
      }
    },
    onToggleInspect(item) {
      const self = this;
      try {
        const data = {
          biddingId: item.id,
          inspected: item.inspected ? "yes" : "no",
        };
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/biddingSystem/updBidding`,
          data,
          self.token
        ).then(res => {
          if (res.data.httpStatus === "OK") {
            self.fetchBiddingList();
            self.showAlert("success", "Inspected updated Successfully!");
          }
        });
      } catch (e) {
        self.showAlert("error", e);
      }
    },

    doEditInspected(biddingId, item) {
      const self = this;
      try {
        const data = {
          biddingId: biddingId,
          inspectedFilePath: item.inspectedFilePath,
          inspected: item.inspected,
        };
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/biddingSystem/updBidding`,
          data,
          self.token
        ).then(res => {
          if (res.data.httpStatus === "OK") {
            self.fetchBiddingList();
            self.editRemarksDialog = false;
            self.showAlert("success", "Updated Successfully!");
          }
        });
      } catch (e) {
        self.showAlert("error", e);
      }
    },
  },
};
